<template>
  <v-container
    :fluid="$vuetify.breakpoint.lgAndUp"
    class="fill-height d-flex flex-column"
    style="height: 90vh"
  >
    <v-row class="ma-0 pa-0 mb-6" align="center">
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="5"
        sm="12"
        class="d-flex flex-column align-center pt-0"
      >
        <v-img width="500" :src="successImage" contain />

        <div class="caption">
          <a
            href="https://br.freepik.com/vetores-gratis/ilustracao-do-conceito-de-inspiracao-de-design_10791980.htm#query=idea&position=7&from_view=author"
            >Imagem de storyset</a
          >
          no Freepik
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="8" xl="7" class="d-flex flex-column">
        <v-card-title
          class="text-h5 font-weight-bold d-flex justify-center break-word text-center amber--text text--accent-4"
        >
          {{ title }}
        </v-card-title>
        <v-list>
          <v-list-item v-for="(item, index) in featuredList" :key="index">
            <v-list-item-icon>
              <v-icon
                v-text="item.icon ? item.icon : 'mdi-check-circle'"
                size="25"
                color="success"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-html="item.title" />
              <v-list-item-subtitle
                v-if="item.subtitle"
                v-html="item.subtitle"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <InlineFeatureRating
          class="mx-4"
          feature-name="Aplicar assinatura de e-mail"
          feature-slug="apply_signature_in_batch"
        />

        <v-card-actions
          :class="`mx-3 mt-6 ${
            $vuetify.breakpoint.smAndDown ? 'flex-column' : ''
          }`"
        >
          <v-btn
            x-large
            :block="$vuetify.breakpoint.xs"
            color="primary"
            :class="`text-none text-body-1 font-weight-medium pr-8 pl-5  ${
              $vuetify.breakpoint.smAndDown ? 'mb-4' : 'mr-4'
            }`"
            @click="primaryAction"
          >
            <v-icon v-text="primaryActionIcon" left size="20" class="mr-4" />
            {{ primaryActionText }}
          </v-btn>

          <v-btn
            x-large
            :block="$vuetify.breakpoint.xs"
            outlined
            color="primary"
            :class="`text-none text-body-1 font-weight-medium px-6`"
            @click="secondaryAction"
          >
            {{ secondaryActionText }}

            <v-icon v-text="'mdi-arrow-right'" right class="ml-4" size="20" />
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics";
import { redirectToTheCommercialManagerGoogleCalendar } from "@/helpers/services/utils";

import InlineFeatureRating from "@/components/general/InlineFeatureRating";

export default {
  name: "SuccessfulAction",

  props: {
    title: {
      type: String,
      default: "Uhul! Assinatura de e-mail aplicada 🎉",
    },
    featuredList: { type: Array, default: () => [] },
  },

  components: { InlineFeatureRating },

  data() {
    return {
      successImage: require("@/assets/images/sign_signature.png"),
    };
  },

  computed: {
    ...mapGetters(["hasTrialPlans", "mainDomain"]),

    primaryActionText() {
      if (this.hasTrialPlans) {
        return "Conversar com a equipe";
      }
      return "Voltar para Aplicar em Massa";
    },

    secondaryActionText() {
      if (this.hasTrialPlans) {
        return "Quero conhecer os planos";
      }
      return "Voltar para modelos";
    },

    primaryActionIcon() {
      if (this.hasTrialPlans) {
        return "mdi-calendar";
      }

      return "mdi-card-bulleted";
    },
  },

  methods: {
    ...mapMutations(["setSidebarMini", "setActionFeedBackMode"]),

    primaryAction() {
      if (this.hasTrialPlans) {
        this.scheduleMeeting();
      } else {
        this.$emit("close");
      }
    },

    secondaryAction() {
      if (this.hasTrialPlans) {
        setFirebaseEvent(
          this.mainDomain,
          "go_to_self_checkout",
          "after_batch_signature_apply"
        );

        this.$router.push({ name: "BillingUpgradePlan" });
      } else {
        this.$router.push({ name: "SignTemplates" });
      }
    },

    scheduleMeeting() {
      setFirebaseEvent(
        this.mainDomain,
        "schedule_meeting",
        "after_batch_signature_apply"
      );
      const clicked_from = "Após aplicar assinatura em massa";

      redirectToTheCommercialManagerGoogleCalendar(clicked_from);
    },
  },

  mounted() {
    this.setActionFeedBackMode(true);
    this.setSidebarMini(true);
  },

  beforeDestroy() {
    this.setActionFeedBackMode(false);
  },
};
</script>
