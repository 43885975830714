<template>
  <v-dialog
    v-model="showHideDialog"
    fullscreen
    hide-overlay
    height="100%"
    scrollable
    transition="dialog-bottom-transition"
    v-resize="onResize"
    @keydown.esc="handleClose"
    @click:outside="handleClose"
  >
    <v-card class="lighten-3 elevation-0" tile flat>
      <v-app-bar
        :absolute="!$vuetify.breakpoint.mobile"
        :app="$vuetify.breakpoint.mobile"
        height="80"
        color="primary"
        dark
        flat
        style="z-index: 999"
      >
        <v-btn icon dark @click="handleClose">
          <v-icon v-text="'mdi-close'" />
        </v-btn>

        <v-app-bar-title>
          {{ $t("applySignature.applyVacationMessage") }}
        </v-app-bar-title>
      </v-app-bar>
      <v-stepper
        v-model="currentStep"
        :key="mobile"
        :disabled="loadingSign"
        flat
        tile
        :vertical="$vuetify.breakpoint.smAndDown"
        class="px-0 mx-0 mt-12 pt-8 overflow-y-auto"
      >
        <v-row
          v-if="$vuetify.breakpoint.mdAndUp"
          :class="`${
            $vuetify.breakpoint.smAndDown ? 'mt-5' : 'primary'
          } ma-0 pa-0`"
          align="end"
          no-gutters
        >
          <v-col v-for="step in stepNames" :key="step.id">
            <v-stepper-step
              :class="`${
                currentStep === step.id ? 'background rounded-t-lg' : ''
              } py-4 ${canClickInThisStep(step.id) ? 'link' : ''}`"
              :complete="currentStep > step.id"
              :step="step.id"
              @click="clickInThisStep(step.id)"
            >
              <div
                :class="`${
                  currentStep === step.id ? 'primary--text' : 'white--text'
                } pl-2 font-weight-medium`"
              >
                {{ step.title }}
              </div>
              <div
                v-if="!$vuetify.breakpoint.mobile"
                :class="`${
                  currentStep === step.id ? 'primary--text' : 'white--text'
                } pl-2 caption mt-1`"
                style="line-height: 14px"
              >
                {{ step.subtitle }}
              </div>
            </v-stepper-step>
          </v-col>
        </v-row>

        <!-- TODO: Aviso de que o usuário já está de férias no Control -->
        <v-row v-if="currentStep === 5 && hasUsersWithVacation" class="mx-10">
          <Alert
            color="warning"
            class="mt-5"
            text
            dense
            expansion
            action-text="Ver informações"
            action-icon="mdi-account-multiple"
            style="width: 100%"
          >
            <span>{{ $t("vacationApply.overwriteWarning") }} </span>
            <template #expansion>
              <ul>
                <li
                  v-for="user in selectedUsesWithVacationSettings"
                  :key="user.email"
                >
                  {{ user.email }}
                </li>
              </ul>
            </template>
          </Alert>
        </v-row>

        <!-- VISÃO MOBILE -->
        <div v-if="mobile" class="mt-8 background fill-height">
          <div v-for="(step, i) in stepNames" :key="step.id">
            <v-stepper-step
              :class="`${
                currentStep === step.id ? 'background rounded-t-lg' : ''
              } py-4 ${canClickInThisStep(step.id) ? 'link' : ''}`"
              :complete="currentStep > step.id"
              :step="step.id"
              @click="clickInThisStep(step.id)"
            >
              <div
                :class="`${
                  currentStep === step.id ? 'primary--text' : 'white--text'
                } text-none pl-2 font-weight-medium`"
              >
                {{ step.title }}
              </div>
              <div
                :class="`${
                  currentStep === step.id ? 'primary--text' : 'white--text'
                } pl-2 caption font-weight-regular`"
              >
                {{ step.subtitle }}
              </div>
              <div
                v-if="!$vuetify.breakpoint.mobile"
                class="pl-2 caption mt-1"
                style="line-height: 14px"
              >
                {{ step.subtitle }}
              </div>
            </v-stepper-step>
            <v-stepper-content :step="step.id" class="px-0 mx-0">
              <v-card flat tile class="overflow-y-auto px-2" :key="currentStep">
                <StartedVacationFlow
                  v-if="step.id === 1"
                  @started="currentStep = 2"
                  :show="step.id === 1"
                />
                <TemplateSelector
                  v-if="step.id === 2"
                  :show="step.id === 2"
                  vacation
                  @html="setTemplate"
                  @clickedTemplate="setSelectedTemplate"
                />
                <VacationSettings
                  v-else-if="step.id === 3"
                  :name="selectedTemplateName"
                  :html="htmlTemplate"
                  :subject="subjectTemplate"
                  @vacationSettings="setVacationSettings"
                />
                <AbsenseSettings
                  v-else-if="step.id === 4"
                  :name="selectedTemplateName"
                  :html="htmlTemplate"
                  :subject="subjectTemplate"
                  @vacationSettings="
                    setVacationSettings($event);
                    currentStep = 5;
                  "
                />
                <ConfirmTemplateApply
                  v-else-if="step.id === 5"
                  :template-subject="subjectTemplate"
                  :template-name="selectedTemplateName"
                  :template-html="htmlTemplate"
                  :selected-users="autocompleteSelected"
                  @change:selected-users="autocompleteSelected = $event"
                  @skip="currentStep = 4"
                  @apply="applyVacation"
                />
                <ManageUserAccess
                  v-else-if="step.id === 6"
                  :vacation-settings="settings || {}"
                  :selected-users="autocompleteSelected"
                  @change:selected-users="autocompleteSelected = $event"
                  @input:vacation-settings="settings = $event"
                  @finish="finish"
                />
              </v-card>
            </v-stepper-content>
            <v-divider v-if="i < stepNames.length - 1" class="mb-3" />
          </div>
        </div>
        <v-stepper-items v-if="!mobile" class="background overflow-y-auto">
          <v-stepper-content
            v-for="step in stepNames"
            :key="step.id"
            :step="step.id"
          >
            <StartedVacationFlow
              v-if="step.id === 1"
              @started="currentStep = 2"
              :show="step.id === 1"
            />
            <TemplateSelector
              v-else-if="step.id === 2"
              :show="step.id === 2"
              vacation
              @html="setTemplate"
              @clickedTemplate="setSelectedTemplate"
            />
            <VacationSettings
              v-else-if="step.id === 3"
              :name="selectedTemplateName"
              :html="htmlTemplate"
              :subject="subjectTemplate"
              @vacationSettings="
                setVacationSettings($event);
                currentStep = 4;
              "
            />
            <AbsenseSettings
              v-else-if="step.id === 4"
              :name="selectedTemplateName"
              :html="htmlTemplate"
              :subject="subjectTemplate"
              @vacationSettings="
                setVacationSettings($event);
                currentStep = 5;
              "
            />
            <ConfirmTemplateApply
              v-else-if="step.id === 5"
              :template-subject="subjectTemplate"
              :templateName="selectedTemplateName"
              :template-html="htmlTemplate"
              :selected-users="autocompleteSelected"
              @change:selected-users="autocompleteSelected = $event"
              @apply="applyVacation"
              @skip="currentStep = 6"
            />
            <ManageUserAccess
              v-else-if="step.id === 6"
              :vacation-settings="settings || {}"
              :selected-users="autocompleteSelected"
              @change:selected-users="autocompleteSelected = $event"
              @input:vacation-settings="settings = $event"
              @finish="finish"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import TemplateSelector from "@/components/sign/general/TemplateSelector.vue";
import VacationSettings from "@/components/sign/vacation/batch/VacationSettings.vue";
import ConfirmTemplateApply from "@/components/sign/vacation/batch/ConfirmTemplateApply.vue";
import ManageUserAccess from "@/components/sign/vacation/batch/ManageUserAccess.vue";
import StartedVacationFlow from "@/components/vacationFlow/StartedVacationFlow.vue";
import AbsenseSettings from "@/components/sign/vacation/AbsenseSettings.vue";

export default {
  name: "VacationApplyInBatchDialog",

  props: {
    show: { type: Boolean, required: true },
    selectedUsers: { type: Array },
    sendAs: { type: Boolean, default: false },
  },

  components: {
    TemplateSelector,
    VacationSettings,
    ConfirmTemplateApply,
    ManageUserAccess,
    StartedVacationFlow,
    AbsenseSettings,
  },

  data() {
    return {
      currentStep: 1,
      localSelectedUsers: [],
      selectedTemplate: null,
      settingsValues: null,
      customTemplate: null,
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },

  computed: {
    ...mapGetters([
      "loadingSign",
      "sendAsEmails",
      "allVacationTemplates",
      "usersWithVacationSettings",
      "users",
    ]),

    settings: {
      get() {
        return this.settingsValues;
      },
      set(value) {
        value = value || {};
        this.settingsValues = { ...this.settingsValues, ...value };
      },
    },

    mobile() {
      return this.windowSize.x <= 959;
    },

    selectedUsesWithVacationSettings() {
      return this.localSelectedUsers.reduce((acc, user) => {
        if (this.usersWithVacationSettings.includes(user.email)) {
          acc.push(user);
        }
        return acc;
      }, []);
    },

    hasUsersWithVacation() {
      return this.selectedUsesWithVacationSettings.length > 0;
    },

    stepNames() {
      return [
        {
          title: "Introdução",
          icon: "mdi-account-multiple",
          subtitle: `Saiba quais configurações podem ser feitas durante o fluxo`,
          id: 1,
        },
        {
          title: this.$t("vacationApply.model.title"),
          icon: "mdi-account-multiple",
          subtitle: this.$t("vacationApply.model.subtitle"),
          id: 2,
        },
        {
          title: this.$t("vacationApply.configure.title"),
          icon: "mdi-card",
          subtitle: this.$t("vacationApply.configure.subtitle"),
          id: 3,
        },
        {
          title: "Configuração de ausência",
          icon: "mdi-card",
          subtitle: `Adicione o evento de férias e encaminhe e-mails durante a ausência`,
          id: 4,
        },
        {
          title: this.$t("vacationApply.save.title"),
          icon: "mdi-account-multiple",
          subtitle: this.$t("vacationApply.save.subtitle"),
          id: 5,
        },
        {
          title: this.$t("vacationApply.manageAccess.title"),
          icon: "mdi-account-multiple",
          subtitle: this.$t("vacationApply.manageAccess.subtitle"),
          id: 6,
        },
      ];
    },
    itemKey() {
      return this.sendAs ? "id" : "id_google";
    },

    allUsers() {
      return this.sendAs ? this.sendAsEmails : this.users;
    },

    autocompleteSelected: {
      get() {
        return this.localSelectedUsers.map((item) => item.email);
      },
      set(newValue) {
        this.localSelectedUsers = this.users.filter((item) =>
          newValue.includes(item.email)
        );
      },
    },

    selectedTemplateKey() {
      return this.selectedTemplate?.key || "";
    },

    selectedTemplateName() {
      return this.selectedTemplate?.name || "";
    },

    htmlTemplate() {
      if (this.selectedTemplate) {
        return this.selectedTemplate.content;
      } else if (this.customTemplate) {
        return this.customTemplate.response_body_html;
      }
      return "";
    },

    subjectTemplate() {
      if (this.selectedTemplate) {
        return this.selectedTemplate.response_subject;
      } else if (this.customTemplate) {
        return this.customTemplate.response_subject;
      }
      return "";
    },

    showHideDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    vacationSettings() {
      return {
        settings: {
          enable_auto_reply: this.settings.enable_auto_reply,
          start_time: this.settings.start_time,
          end_time: this.settings.end_time,
          response_subject: this.subjectTemplate,
          response_body_plain_text: null,
          restrict_to_contacts: this.settings.restrict_to_contacts,
          restrict_to_domain: this.settings.restrict_to_domain,
          response_body_html: this.htmlTemplate,
          create_calendar_event: this.settings.create_calendar_event,
          calendar_event_name: this.settings.calendar_event_name,
          forwarding_email_on_vacation:
            this.settings.forwarding_email_on_vacation,
        },
      };
    },

    isVacationFlow() {
      return this.$route.name === "VacationMessage";
    },
  },

  watch: {
    showHideDialog() {
      if (this.showHideDialog) {
        this.currentStep = 1;
      }
      if (this.$route.query.key) {
        this.selectTemplate(this.$route.query.key);
      }
    },

    "settings.start_time"() {
      if (this.settings.start_time) {
        this.setTemporaryVacationStartDate(this.settings.start_time);
      }
    },

    "settings.end_time"() {
      if (this.settings.end_time) {
        this.setTemporaryVacationEndDate(this.settings.end_time);
      }
    },

    selectedUsers: {
      handler() {
        this.localSelectedUsers = this.selectedUsers.slice();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations([
      "setSnackBar",
      "setTemporaryVacationStartDate",
      "setTemporaryVacationEndDate",
    ]),
    ...mapActions(["updateVacationInBatch"]),

    handleClose(reset = false) {
      if (this.isVacationFlow) {
        this.returnToPreviousPage();
      } else {
        this.$emit("reset", reset);
      }
    },

    returnToPreviousPage() {
      this.$router.go(-1);
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    async applyVacation(users) {
      let payload = {
        vacation_template_key: this.selectedTemplateKey,
        emails: users,
        vacation: this.vacationSettings,
      };

      await this.updateVacationInBatch(payload)
        .then(() => (this.currentStep = 6))
        .catch(() => this.handleClose(true));
    },

    /* Verify if it's possible to select a step */
    canClickInThisStep(step) {
      return step < this.currentStep;
    },

    /* Click in a completed step */
    clickInThisStep(step) {
      if (this.canClickInThisStep(step)) {
        this.currentStep = step;
      }
    },

    /* Save vacation settings like init and end date, etc */
    setVacationSettings(vacation) {
      this.settings = vacation;
    },

    /* Used when it only has a html template that is not saved in custom or
      default templates (it's a new template)
    */
    setTemplate(template) {
      this.customTemplate = template;
      this.currentStep = 2;
    },

    /* Select a custom or default template to use */
    setSelectedTemplate(template) {
      this.selectTemplate(template.key);
      this.currentStep = 3;
    },

    /* Search and set a custom or default template to use */
    selectTemplate(key) {
      this.selectedTemplate = this.allVacationTemplates.find(
        (template) => template.key === key
      );

      if (this.selectedTemplate) {
        this.currentStep = 2;
      } else {
        this.setSnackBar({
          color: "error",
          show: true,
          message: errorMessages.select_template_to_apply,
        });
        setTimeout(() => this.setErrorRedirect(), 3000);
      }
    },

    setErrorRedirect() {
      this.$emit("close");
      this.$router.push("/sign/template");
    },

    finish() {
      this.handleClose();
      this.showHideDialog = false;
    },
  },

  async beforeMount() {
    if (this.$route.query.key) {
      await this.selectTemplate(this.$route.query.key);
    }
  },

  mounted() {
    this.onResize();
  },
};
</script>

<style>
v-toolbar__extension {
  padding: 0px !important;
}

.v-stepper__step__step,
.v-stepper__step__step.primary {
  background-color: var(--v-accent-base) !important;
  border-color: var(--v-accent-base) !important;
}
</style>
