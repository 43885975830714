<template>
  <SuccessfulAction
    v-if="hasSuccessResponse"
    :featured-list="appliedSignatureFeatureList"
    @close="hasSuccessResponse = false"
  />
  <v-card
    v-else
    elevation="0"
    class="px-0 mt-5"
    style="height: calc(100% - 68px) !important"
  >
    <FilterEmailsTabs
      v-if="$vuetify.breakpoint.xs ? !showMobileFilters : true"
      :disableForUsersWithoutVacationMessage="hasUserWithoutVacationMessage"
      :disabled="loading"
      :loading="showLoading"
      :loading-sync="loadingSyncSendAs || loadingSyncUsersAndUOs"
      :total-users-number="currentPrimaryEmails"
      :total-secondary-number="currentAllEmailsTypes"
      :selected-number="selectedNumber"
      :itens-per-page="pagination.itemsPerPage"
      @apply:signature="selectSignatureModel = true"
      @apply:signature-to-secondary="selectSignatureModel = true"
      @apply:vacation-message="showVacationDialog = true"
      @create:secondary-emails="showApplySendAsDialog = true"
      @remove:vacation-message="deleteUserVacation(false)"
      @select:all="setAllUsersSelected"
      @select:clear="
        currentSelected = [];
        setAllEntitiesFilteredToApply([]);
      "
      @update:user-tags="openRequestChangeTagsDialog"
      @update:users-csv="showCSV = true"
      @insert:users-calendar="showCalendarsDialog = true"
      @openCreateGroup="showCreateGroup = true"
    />
    <div
      :class="{
        'pa-10 px-16': $vuetify.breakpoint.xl,
        'pa-10 px-5': $vuetify.breakpoint.lgAndDown,
      }"
      style="
        background-color: #f1f4f8;
        height: auto;
        min-height: 100% !important;
      "
    >
      <div class="mb-10">
        <SearchFilterBar
          v-if="showSendAsEmails"
          class="mt-0 pt-0"
          :label="filterBarLabel"
          placeholder=""
          :applied-filters="appliedFiltersToDisplay"
          :disabled="currentSelected.length"
          :loading="showLoading"
          :totalPageUsers="totalPageUsers"
          :loadingSync="loadingSyncSendAs || loadingSyncUsersAndUOs"
          @search-by="updateFilters"
          @input-search="setSearch"
          @remove:filter="removeFilter"
          @sync="sync"
          is-user-table
          :show-send-as="showSendAsEmails"
          key="filter-users-with-sendas"
        />
        <SearchFilterBar
          v-else
          key="filter-users"
          class="mt-0 pt-0"
          :label="filterBarLabel"
          placeholder=""
          :applied-filters="appliedFiltersToDisplay"
          :loading="showLoading"
          :loadingSync="loadingSyncSendAs || loadingSyncUsersAndUOs"
          :disabled="currentSelected.length"
          :totalPageUsers="totalPageUsers"
          @search-by="updateFilters"
          @input-search="setSearch"
          @remove:filter="removeFilter"
          @sync="sync"
          is-user-table
        />
      </div>
      <div>
        <UsersTable
          showSelect
          :headers="headers"
          :itemKey="itemKey"
          :users="allUsers"
          :totalUsers="currentTotal"
          :loading="isLoading"
          :pagination="pagination"
          :currentSelected="currentSelected"
          :sort-by.sync="pagination.sortBy"
          :sort-desc.sync="pagination.sortDesc"
          @paginationChange="paginationChangeHandler"
          @currentSelectedChange="currentSelected = $event"
          @toggleSelectAll="getPageSelection"
          :class="`sign-table ${isLoading ? 'hide-table' : ''}`"
          data-cy="applyMailSignature"
        >
          <template v-slot:top>
            <div id="userTableHeader">
              <v-scroll-y-transition>
                <Alert
                  v-if="showSelectedTemplateMessage"
                  color="success"
                  class="ma-0"
                  :text="false"
                >
                  {{
                    templateKey
                      ? $t("applySignature.vacationModel")
                      : $t("applySignature.signatureModels")
                  }}
                  <b
                    >"{{
                      templateKey
                        ? selectedVacationTemplateName
                        : selectedTemplateName
                    }}"</b
                  >
                  {{ $t("common.selected") }}.
                  {{
                    templateKey
                      ? $t("applySignature.applyVacationModelInstructions")
                      : $t("applySignature.applySignatureModelInstructions")
                  }}

                  <v-btn @click="clear" small class="ml-2" color="success" icon>
                    <v-icon v-text="'mdi-close'" size="24" />
                  </v-btn>
                </Alert>
              </v-scroll-y-transition>

              <v-btn
                v-if="$vuetify.breakpoint.xs && !showSendAsEmails"
                block
                large
                text
                color="secondary lighten-2"
                class="text-none text-body-1 font-weight-medium mt-2 mx-2"
                @click="showMobileFilters = !showMobileFilters"
              >
                <v-icon left v-text="'mdi-filter-variant'" />
                {{ showMobileFilters ? "Ocultar filtros" : "Aplicar filtros" }}
              </v-btn>

              <v-scroll-y-transition>
                <Alert
                  v-if="allSelectedAreInTheSamePage || allSelected"
                  class="ma-0 text-center"
                >
                  <p
                    v-if="allSelectedAreInTheSamePage && !allSelected"
                    class="ma-0"
                  >
                    {{ $t("common.all") }} {{ selectedNumber }}
                    {{ $t("usersPage.usersOnPageSelecteds") }}.
                    <span
                      class="font-weight-bold lighteen-blue--text"
                      @click="setAllUsersSelected"
                      style="cursor: pointer"
                    >
                      <span v-if="hasAppliedFilters">{{
                        $t("usersPage.selectAllUsersInSearch")
                      }}</span>
                      <span v-else>
                        {{ $t("usersPage.selectAllUsers") }}.
                      </span>
                    </span>
                  </p>

                  <p v-if="allSelected" class="ma-0">
                    {{ $t("common.all") }} {{ selectedNumber }}
                    {{ $t("usersPage.usersOnTableSelecteds") }}.
                    <span
                      class="font-weight-bold lighteen-blue--text"
                      @click="
                        currentSelected = [];
                        setAllEntitiesFilteredToApply([]);
                      "
                      style="cursor: pointer"
                      >{{ $t("action.clearSelection") }}.</span
                    >
                  </p>
                </Alert>
              </v-scroll-y-transition>
              <v-skeleton-loader
                v-if="isLoading"
                type="table"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`header.applied_signature_key`]="{ header }">
            {{ header.text }}
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                  size="20"
                  v-text="'mdi-help-circle'"
                />
              </template>
              <span>{{ $t("filters.signatureModelAppliedDescription") }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`header.applied_vacation_key`]="{ header }">
            {{ header.text }}
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                  size="20"
                  v-text="'mdi-help-circle'"
                />
              </template>
              <span>{{
                $t("filters.vacationMessageTemplateAppliedDescription")
              }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`header.user.name`]="{ header }">
            {{ header.text }}
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                  small
                  v-text="'mdi-help-circle'"
                />
              </template>
              <span
                >{{ $t("usersPage.clickOnTheUserToConfigureSendas") }}
              </span>
            </v-tooltip>
          </template>

          <template v-if="!showSendAsEmails" v-slot:header.actions>
            <CustomerTableColsSelector
              :usersColsTable="true"
              @change:table-cols="changeColumnsView"
              tableHeader="customUserTableCols"
            />
          </template>

          <template v-slot:[`item.user.name`]="{ item }">
            <UserBadge :user="item" size="40" link chip />
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span
              v-if="showSendAsEmails"
              :class="`${item.is_primary_email ? 'font-weight-bold' : ''}`"
            >
              {{
                item.is_primary_email ? item.name || item.user.name : item.name
              }}
            </span>
            <v-row v-else align="center" class="ma-0 sign-table">
              <UserBadge
                :user="item"
                size="40"
                redirect-to-user
                :showUserPosition="true"
                link
              />
            </v-row>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <TooltipIcon
              v-if="!item.is_gmail_enabled && showSendAsEmails"
              :label="$t('common.emailServiceIsDisabled')"
              max-width="400"
              icon="mdi-email-off"
              size="20"
            />
            <TooltipIcon
              v-else-if="!item.is_editable && showSendAsEmails"
              :label="$t('common.userRestrictDescription')"
              max-width="400"
              icon="mdi-lock"
              size="20"
            />
            <span
              :class="`${
                item.is_primary_email && showSendAsEmails
                  ? 'font-weight-bold'
                  : ''
              } mx-0 px-0`"
            >
              {{ item.email }}
              <v-tooltip v-if="item.is_primary_email" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-text="'mdi-star-circle'"
                    color="accent"
                    dense
                    class="mr-2"
                  />
                </template>
                <span>{{ $t("common.userPrimaryEmail") }}</span>
              </v-tooltip>
            </span>
          </template>

          <!-- PRIMARY EMAIL -->
          <template v-slot:[`item.is_primary_email`]="{ item }">
            <span
              :class="`${
                item.is_primary_email ? 'font-weight-bold' : ''
              } break-word`"
            >
              {{
                item.is_primary_email
                  ? $t("common.primary")
                  : $t("common.secondary")
              }}
            </span>
          </template>

          <!-- IS_ADMIN -->
          <template v-slot:[`item.is_google_admin`]="{ item }">
            {{ item.is_google_admin ? "Administrador" : "Colaborador" }}
          </template>

          <!-- GMAIL ENABLED -->
          <template v-slot:[`item.is_gmail_enabled`]="{ item }">
            {{ item.is_gmail_enabled ? "Ativo" : "Inativo" }}
          </template>

          <template v-slot:[`item.departament`]="{ item }">
            <span v-if="item.departament" class="break-word">
              {{ item.departament }}
            </span>
            <span v-else>-</span>
          </template>

          <template v-slot:[`item.applied_signature_key`]="{ item }">
            <span v-if="getSignatureModelName(item.applied_signature_key)">
              {{ getSignatureModelName(item.applied_signature_key) }}
            </span>
            <span v-else>-</span>
          </template>
          <template v-slot:[`item.applied_vacation_key`]="{ item }">
            <span
              v-if="
                item.applied_vacation_key &&
                !checkExpiredDate(item.vacation_end_date)
              "
            >
              {{ getVacationTemplateName(item.applied_vacation_key) }}
            </span>
            <span v-else>-</span>
          </template>
          <template v-slot:[`item.last_login`]="{ item }">
            <div v-if="checkIfNeverLoggedIn(item.last_login)">
              {{ $t("techDiagnosis.generalRow.neverLogged") }}
            </div>
            <label v-else>{{ formatDateAndHour(item.last_login) }}</label>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  data-testid="options"
                  icon
                  class="ml-auto"
                  :id="firstId === item.key ? 'v-step-0' : ''"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disableIndividualActions"
                >
                  <v-icon v-text="'mdi-dots-vertical'" />
                </v-btn>
              </template>
              <v-list style="width: 350px" dense class="py-0">
                <v-list-item
                  v-for="(option, i) in userOptions"
                  :disabled="
                    option.disabled ||
                    !companyHasSignActive ||
                    !hasSignPermission
                  "
                  :key="i"
                  @click="execute(option.value, item)"
                  class="text-body-2 font-weight-medium"
                  :data-testid="`user-option-${i}`"
                >
                  {{ option.text }}
                </v-list-item>
                <div
                  v-if="!companyHasSignActive || !hasSignPermission"
                  class="sign-permission-overlay"
                >
                  <div v-if="!companyHasSignActive" class="sign-plan-text">
                    <v-icon class="mr-3" v-text="'mdi-crown'"></v-icon>
                    <div>
                      <p class="ma-0">
                        Disponivel apenas para planos Standard +
                      </p>
                      <p
                        class="ma-0 mt-1"
                        style="font-size: 12px; font-weight: normal"
                      >
                        Ações em massa e assinaturas de e-mail não estão
                        disponiveis para o seu plano atual, contrate agora para
                        utilizar.
                      </p>
                    </div>
                  </div>
                  <div
                    v-else-if="!hasSignPermission"
                    class="sign-permission-text"
                  >
                    <v-icon
                      color="white"
                      class="mr-3"
                      v-text="'mdi-alert-circle'"
                    ></v-icon>
                    <div>
                      <p class="ma-0">
                        Você não possui permissão para gerenciar assinaturas de
                        e-mail.
                      </p>
                      <p
                        class="ma-0 mt-1"
                        style="font-size: 12px; font-weight: normal"
                      >
                        Solicite ao administrador para liberar seu acesso!
                      </p>
                    </div>
                  </div>
                </div>
                <v-divider
                  v-if="item.applied_signature_key || item.applied_vacation_key"
                />
                <v-list-item
                  v-if="item.applied_signature_key"
                  class="text-body-2 font-weight-medium"
                  @click="setPreview(item)"
                >
                  {{ $t("common.viewAppliedSignature") }}
                </v-list-item>
                <v-list-item
                  v-if="item.applied_vacation_key && !showSendAsEmails"
                  class="text-body-2 font-weight-medium"
                  @click="setPreview(item, true)"
                >
                  {{ $t("common.viewAppliedVacationMessage") }}
                </v-list-item>

                <v-divider
                  v-if="item.applied_vacation_key && !showSendAsEmails"
                />
                <v-list-item
                  v-if="item.applied_vacation_key && !showSendAsEmails"
                  class="text-body-2 font-weight-medium"
                  @click="deleteUserVacation(item.email)"
                >
                  {{ $t("action.removeVacationMessage") }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </UsersTable>
      </div>
    </div>
    <!-- FIM DA TABELA DE USUÁRIOS -->

    <Snackbar
      :show="showSnackbar"
      :message="snackbarText"
      :color="snackbarType"
      @close="showSnackbar = false"
    />

    <DialogEditUserTags
      :user="selectedUser"
      :show="showUserCardSignature"
      @close="close"
      @update:user="updateUserInSelectedList"
    />

    <ApplySignatureSteps
      v-if="!loading"
      :current-selected="handleCurrentSelected"
      :select-signature-model="selectSignatureModel"
      :selected-template="selectedTemplate"
      @update:current-selected="updateSelectedUsers"
      @update:current-selected-template="updateSelectedTemplate"
      @update:select-signature-model="updateShowSelectSignature"
      @update:table-users-signature="tempUpdateSignature"
      @update:users-table-data="getUsersTable"
    />

    <VacationApplyInBatchDialog
      :key="hasSelectedUsers"
      :show="showVacationDialog"
      :selected-users="handleCurrentSelected"
      @reload="closeVacationApplyActions(true)"
      @reset="closeVacationApplyActions"
    />

    <ApplySendAs
      :show="showApplySendAsDialog"
      :users="handleCurrentSelected"
      @close="closeSendAsDialog"
    />

    <InsertUsersCalendars
      :show="showCalendarsDialog"
      :users="handleCurrentSelected"
      @close="closeCalendarsDialog"
    />

    <!-- DIALOG USUARIO RESTRITO -->
    <HeaderDialog
      v-if="showRestrictedDialog"
      id="apply_restricted_user"
      color="primary"
      :title="
        currentSelected[0].is_editable
          ? $t('common.makeUserRestricted')
          : $t('common.removeUserRestriction')
      "
      :show="showRestrictedDialog"
      :persistent="false"
      :loading="loading"
      width="700"
      @close="closeRestrictedDialog"
      @action="turnRestrictedUser"
      :closeText="$t('action.cancel')"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
    >
      <template v-if="this.currentSelected[0].is_editable" v-slot:body>
        <strong> {{ $t("common.makeThisUserRestrict") }} </strong>
        <br />
        <p class="mt-3">
          {{ $t("common.descriptionMakeUserRestricted") }}
        </p>
        <v-col>
          <v-play-video-button
            :smallbtn="true"
            justify_btn="start"
            title="Restrições de aplicação de assinatura para usuários"
            id_youtube_video="z5XSGuJFxPw"
          />
        </v-col>
      </template>
      <template v-else v-slot:body>
        <strong> {{ $t("common.removeRestrictionFromThisUser") }} </strong>
        <br />
        <p class="mt-3">
          {{ $t("common.descriptionRemoveRestrictionFromThisUser") }}
        </p>
        <v-alert text prominent color="warning" class="body-2">
          {{ $t("common.alertRemoveRestrictionFromThisUser") }}
        </v-alert>
      </template>
    </HeaderDialog>

    <!-- REQUEST INFO CHANGE DIALOG -->
    <HeaderDialog
      v-if="showRequestChangeTags"
      :show="showRequestChangeTags"
      id="request_change_tags"
      color="primary"
      :title="$t('userInformations.request')"
      :persistent="false"
      :loading="loading"
      width="700"
      @close="closeRequestChangeTagsDialog"
      @action="
        sendUserTagsUpdateRequest(
          userToRequestInfoChange && [userToRequestInfoChange]
        )
      "
      :closeText="$t('action.cancel')"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
    >
      <template v-slot:body>
        <p class="mt-3">{{ $t("userInformations.emailSending") }}</p>

        <p>{{ $t("userInformations.process") }}</p>

        <ol class="my-4">
          <li>{{ $t("userInformations.emailReceipt") }}</li>
          <li>{{ $t("userInformations.linkAccess") }}</li>
        </ol>

        <Alert text dense>
          {{ $t("userInformations.deadline") }}
        </Alert>
      </template>
    </HeaderDialog>

    <!-- DIALOG PREVIEW -->
    <DialogPreview
      v-if="previewTemplate"
      :key="previewTemplate"
      :show="contentPreview.show"
      :name="contentPreview.template.name"
      :html="previewTemplate"
      :info-to-render="contentPreview.user"
      :subject="previewSubject"
      :is-vacation-preview="previewIsVacation"
      hide-top
      hide-bottom
      @close="contentPreview.show = false"
    />

    <!-- UPDATE USERS CSV -->
    <UsersTagsCSV
      :show="showCSV"
      @close="showCSV = false"
      @updateUsersTableData="getUsersTable"
    />

    <!-- CREATE GROUPS -->
    <CreateGroups :show="showCreateGroup" @close="showCreateGroup = false" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import FilterEmailsTabs from "@/components/sign/users/FilterEmailsTabs";
import SearchFilterBar from "@/components/general/SearchFilterBar";
import DialogPreview from "@/components/sign/general/DialogPreview";
import ApplySendAs from "@/components/sign/send_as/ApplySendAs.vue";
import InsertUsersCalendars from "@/components/sign/users/InsertUsersCalendars.vue";
import DialogEditUserTags from "@/components/sign/tags/DialogEditUserTags.vue";
import VacationApplyInBatchDialog from "@/components/sign/vacation/VacationApplyInBatchDialog";
import UsersTable from "@/components/user/UsersTable";
import SuccessfulAction from "@/components/general/SuccessfulAction";
import ApplySignatureSteps from "./ApplySignatureSteps.vue";
import UsersTagsCSV from "@/components/sign/tags/UsersTagsCSV.vue";
import CreateGroups from "@/components/groups/CreateGroups.vue";

import {
  setSearchByString,
  updateAppiledFiltersValues,
} from "@/helpers/services/filters";
import {
  getPaginationDataFromApi,
  checkIfNeverLoggedIn,
} from "@/helpers/services/users";
import {
  checkExpiredDate,
  formatDate,
  validateEmail,
  setSignatureFeedbackPage,
  formatDateAndHour,
} from "@/helpers/services/utils";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { paging } from "@/helpers/variables/tablesDefault";
import CustomerTableColsSelector from "@/components/reseller/CustomerTableColsSelector";
import {
  usersTableHeader,
  sendasTableHeaders,
} from "@/helpers/variables/clients";

import { userEditableTags } from "@/helpers/variables/tags.js";

export default {
  name: "SignUsersTable",

  components: {
    UsersTable,
    ApplySignatureSteps,
    DialogEditUserTags,
    ApplySendAs,
    InsertUsersCalendars,
    VacationApplyInBatchDialog,
    DialogPreview,
    SearchFilterBar,
    FilterEmailsTabs,
    SuccessfulAction,
    UsersTagsCSV,
    CustomerTableColsSelector,
    CreateGroups,
  },
  data() {
    return {
      usersWithoutSomeTag: [],
      showMobileFilters: false,
      currentPrimaryEmails: 0,
      currentAllEmailsTypes: 0,
      loadingSyncSendAs: false,
      showSendAsEmails: false,
      showUserCardSignature: false,
      showCreateGroup: false,
      selectedUser: {},
      showVacationDialog: false,
      showSnackbar: false,
      snackbarType: "success",
      snackbarText: "",
      steps: 1,
      selectSignatureModel: false,
      showLoading: false,
      currentSelected: [],
      currentFilters: [],
      vacation: {},
      selectedTemplate: {},
      vacationTemplate: null,
      usersInTable: [],
      totalUsers: 0,
      showCSV: false,
      pagination: {
        sortBy: ["name"],
        sortDesc: [false],
        page: 1,
        itemsPerPage: paging.perPage,
      },
      allItemsInThePage: [],
      showApplySendAsDialog: false,
      showCalendarsDialog: false,
      showRestrictedDialog: false,
      loading: false,
      previewSettings: {
        show: false,
        user: null,
        template: null,
        is_vacation: false,
      },
      hasSuccessResponse: false,
      loadingSyncUsersAndUOs: false,
      sendasTableHeaders,
      headersToShow: null,
      userToRequestInfoChange: "",
      showRequestChangeTags: false,
    };
  },

  computed: {
    ...mapGetters([
      "users",
      "sendAsEmails",
      "allEmailsInCompany",
      "allTemplates",
      "allVacationTemplates",
      "token",
      "showVacationMessageBanner",
      "usersWithVacationSettings",
      "usersCanceledResquest",
      "displayOnlyPrimaryEmails",
      "allEntitiesFiltered",
      "allEntitiesFilteredToApply",
      "hasSignPermission",
      "companyHasSignActive",
      "hasConectaSign",
    ]),

    handleCurrentSelected() {
      return this.allEntitiesFilteredToApply.length
        ? this.allEntitiesFilteredToApply
        : this.currentSelected;
    },

    appliedSignatureFeatureList() {
      return [
        {
          title: `A assinatura de e-mail mudará para o modelo <b>${this.selectedTemplateName}</b> em breve`,
          subtitle: `A aplicação pode demorar até 15 minutos para ser visualizada.`,
        },
        {
          title: `Os usuários definidos como restrito deverão aceitar a aplicação primeiro para terem suas assinaturas alteradas`,
          subtitle: `Saiba mais sobre usuários restritos <a href="https://www.youtube.com/watch?v=z5XSGuJFxPw" target="_blank">clicando aqui</a>.`,
        },
      ];
    },

    filtersToSearch() {
      return this.showSendAsEmails
        ? ["name", "email", "user.name", "user.email"]
        : ["name", "email"];
    },

    hasUserWithoutVacationMessage() {
      const usersWithVacationMessage = [];
      this.currentSelected.forEach((user) => {
        if (user?.applied_vacation_key) {
          usersWithVacationMessage.push("true");
        }
      });

      const currentSelectedSet = new Set(this.currentSelected);

      if (usersWithVacationMessage.length !== currentSelectedSet.size) {
        return true;
      }

      return false;
    },

    filterBarLabel() {
      return this.showSendAsEmails
        ? this.$t("usersPage.secondarySearchBy")
        : this.$t("usersPage.primarySearchBy");
    },

    currentTotal() {
      return this.showSendAsEmails
        ? this.currentAllEmailsTypes
        : this.currentPrimaryEmails;
    },

    isLoading() {
      if (this.showSendAsEmails || !this.displayOnlyPrimaryEmails) {
        return this.loadingSyncSendAs || this.showLoading || this.loading;
      }
      return this.showLoading || this.loadingSyncUsersAndUOs || this.loading;
    },

    appliedFiltersToDisplay() {
      return this.currentFilters.filter(
        (item) =>
          !["name", "user.name", "email", "user.email"].includes(item.value) &&
          item.search !== ""
      );
    },

    hasAppliedFilters() {
      return this.appliedFiltersToDisplay.length;
    },

    hasVacationTemplatePreSelected() {
      return this.templateKey && this.selectedVacationTemplateName;
    },

    hasSignatureTemplatePreSelected() {
      return this.templateId && this.selectedTemplateName;
    },

    showSelectedTemplateMessage() {
      return (
        this.hasVacationTemplatePreSelected ||
        this.hasSignatureTemplatePreSelected
      );
    },

    previewIsVacation() {
      return this.contentPreview.is_vacation;
    },

    contentPreview: {
      get() {
        return this.previewSettings;
      },
      set(new_value) {
        return (this.previewSettings = new_value);
      },
    },

    previewTemplate() {
      if (this.previewIsVacation) {
        return this.contentPreview.template
          ? this.contentPreview.template.content
          : "";
      }
      return this.contentPreview.template
        ? this.contentPreview.template.html
        : "";
    },

    previewSubject() {
      if (this.contentPreview.is_vacation) {
        return this.contentPreview.response_subject
          ? this.contentPreview.template.response_subject
          : "";
      }
      return "";
    },

    isTemplateEmpty() {
      return !this.selectedTemplate.html ? true : false;
    },

    tagsMissing: {
      get() {
        return this.usersWithoutSomeTag;
      },
      set(newVal) {
        this.usersWithoutSomeTag = newVal;
      },
    },

    selectedVacationTemplateName() {
      return this.vacationTemplate ? this.vacationTemplate.name : "";
    },

    allUsers: {
      get() {
        return this.usersInTable;
      },
      set(new_value) {
        this.usersInTable = new_value;
      },
    },

    pageUsers() {
      const lastIndexOfPage = this.pagination.itemsPerPage;
      return this.allUsers.slice(0, lastIndexOfPage);
    },

    totalPageUsers() {
      return this.pageUsers.length;
    },

    templateId() {
      return this.$route.query.id || null;
    },

    templateKey() {
      return this.$route.query.key || null;
    },

    autocompleteSelected: {
      get() {
        return this.currentSelected.map((item) => item[this.itemKey]);
      },
      set(newValue) {
        this.currentSelected = newValue.map((value) =>
          this.allItems.find((item) => item[this.itemKey] == value)
        );
      },
    },

    headers: {
      get() {
        if (this.showSendAsEmails) {
          return this.sendasTableHeaders;
        }

        const tableCols =
          this.headersToShow ||
          JSON.parse(localStorage.getItem("customUserTableCols")) ||
          usersTableHeader;

        const translatedCols = [];

        tableCols.forEach((item) => {
          const columnData = usersTableHeader.find(
            (col) => col.value === item.value
          );

          if (item.show && columnData) {
            translatedCols.push(columnData);
            return columnData;
          }
        });

        return translatedCols;
      },
      set(newValues) {
        this.headersToShow = newValues;
      },
    },

    disableIndividualActions() {
      if (this.currentSelected.length > 0) {
        return true;
      }

      return false;
    },

    hasFiltersToApply() {
      return this.currentFilters.length > 0;
    },

    allSelectedAreInTheSamePage() {
      if (this.selectedNumber > 0) {
        return (
          this.currentSelected.every((item) =>
            this.allItemsInThePage.includes(item)
          ) && this.selectedNumber >= this.allItemsInThePage.length
        );
      } else {
        return false;
      }
    },

    allSelected() {
      return this.hasSelectedUsers && this.selectedNumber === this.totalUsers;
    },

    selectedTemplateName() {
      return this.selectedTemplate.name;
    },

    firstId() {
      const users = [...this.allUsers];

      const firstUserIndex = users.length === 1 ? 0 : 1;

      return users[firstUserIndex]["key"];
    },

    userOptions() {
      return [
        {
          text: this.$t("applySignature.applyEmailSignature"),
          value: "email",
          show: true,
        },
        {
          text: this.$t("applySignature.applyVacationMessage"),
          value: "vacation",
          show: !this.showSendAsEmails,
        },
        {
          text: this.$t("userInformations.title"),
          value: "tags",
          show: !this.showSendAsEmails,
        },
        {
          text: this.$t("common.requestUserUpdateInfo"),
          value: "request_tags_update",
          show: true,
        },
        {
          text: this.$t("common.userRestrictionChange"),
          value: "restricted",
          show: !this.showSendAsEmails,
        },
      ].filter((item) => item.show);
    },

    hasSelectedUsers() {
      return this.selectedNumber > 0;
    },

    selectedNumber() {
      if (this.allEntitiesFilteredToApply.length) {
        return this.allEntitiesFilteredToApply.length;
      }
      return this.currentSelected.length;
    },

    emailsFromSelectedUsers() {
      return this.currentSelected.map((user) => user.email);
    },

    selectedUsersWithVacation() {
      return this.emailsFromSelectedUsers.reduce((acc, email) => {
        if (this.usersWithVacationSettings.includes(email)) {
          acc.push(email);
        }
        return acc;
      }, []);
    },

    currentOffset() {
      const { page, itemsPerPage } = this.pagination;
      return (page - 1) * itemsPerPage;
    },

    allItems() {
      return this.showSendAsEmails ? this.allEmailsInCompany : this.users;
    },

    itemKey() {
      return this.showSendAsEmails ? "id" : "id_google";
    },
  },

  watch: {
    displayOnlyPrimaryEmails() {
      this.showSendAsEmails = !this.displayOnlyPrimaryEmails;
    },

    usersCanceledResquest() {
      if (this.usersCanceledResquest) {
        this.showLoading = true;
      }
    },

    "contentPreview.show"() {
      if (!this.contentPreview.show) {
        this.contentPreview = {
          show: false,
          user: null,
          template: null,
          is_vacation: false,
        };
      }
    },

    steps() {
      if (Object.keys(this.selectedTemplate).length > 0) {
        this.tagsMissing = this.getSelectedTagsMissing();
      }
    },

    selectedTemplate() {
      if (Object.keys(this.selectedTemplate).length > 0) {
        this.tagsMissing = this.getSelectedTagsMissing();
      }
    },

    autocompleteSelected() {
      if (Object.keys(this.selectedTemplate).length > 0) {
        this.tagsMissing = this.getSelectedTagsMissing();
      }
    },

    "$route.query.id"() {
      if (this.templateId) {
        this.selectTemplateToApply(this.$route.query.id);
        this.tagsMissing = this.getSelectedTagsMissing();
      }
    },

    "$route.query.key"() {
      if (this.templateKey) {
        this.selectVacationTemplate();
        this.tagsMissing = this.getSelectedTagsMissing();
      }
    },

    selectSignatureModel() {
      if (!this.selectSignatureModel) {
        this.steps = 1;
      }
    },

    showSendAsEmails() {
      this.pagination = {
        ...this.pagination,
        sortBy: this.showSendAsEmails ? ["user.name"] : ["name"],
        sortDesc: [false],
        page: 1,
      };
      this.resetFilters();
    },
  },

  methods: {
    ...mapActions([
      "syncSendAs",
      "syncUsers",
      "getSignatureTemplates",
      "getVacationTemplates",
      "getUsersByTablePage",
      "getSendAsByTablePage",
      "syncUos",
      "updateVacationInBatch",
    ]),
    ...mapMutations([
      "setSnackBar",
      "setUsers",
      "setAllEntitiesFiltered",
      "setAllItensSelected",
      "setAllEntitiesFilteredToApply",
    ]),

    formatDate,

    validateEmail,

    checkExpiredDate,

    checkIfNeverLoggedIn,

    formatDateAndHour,

    changeColumnsView(newCols) {
      this.headers = newCols.slice().filter((item) => item.show);
    },

    updateSelectedUsers(val) {
      this.currentSelected = val;
    },

    updateSelectedTemplate(val) {
      this.selectedTemplate = val;
    },

    updateShowSelectSignature(val) {
      this.selectSignatureModel = val;
    },

    updateUserInSelectedList(updatedUser) {
      const index = this.currentSelected.findIndex(
        (user) => user.id_google === updatedUser.id_google
      );

      if (index >= 0) {
        this.currentSelected[index] = updatedUser;
      }

      this.tagsMissing = this.getSelectedTagsMissing();
    },

    async setAllUsersSelected() {
      if (this.showSendAsEmails || !this.displayOnlyPrimaryEmails) {
        this.currentSelected = this.allEmailsInCompany;
      } else {
        this.currentSelected = this.users;
      }

      this.setAllEntitiesFilteredToApply([...this.allEntitiesFiltered]);
    },

    getSelectedTagsMissing() {
      if (!this.isTemplateEmpty) {
        const editableTagsKeys = Object.keys(userEditableTags);
        return this.currentSelected.reduce((acc, user) => {
          const userInfo = Object.entries(user);
          let userMissingTags = [];
          userInfo.forEach(([key, value]) => {
            if (editableTagsKeys.includes(key)) {
              if (
                !value &&
                this.selectedTemplate.html.includes(userEditableTags[key].label)
              ) {
                userMissingTags.push(userEditableTags[key].keyName);
              }
            }
          });
          if (userMissingTags.length > 0) {
            acc[user.name] = {
              tags: userMissingTags,
              user: user,
            };
          }

          return acc;
        }, {});
      }

      return {};
    },

    sync() {
      if (this.showSendAsEmails) {
        this.syncSendAsEmails();
      } else {
        this.syncUsersAndUOs();
      }
    },

    setPreview(user, vacation = false) {
      this.contentPreview.is_vacation = vacation;

      if (this.contentPreview.is_vacation) {
        this.setVacationTemplateToPreview(user.applied_vacation_key);
      } else {
        this.setSignatureTemplateToPreview(user.applied_signature_key);
      }
      this.contentPreview.user = user;
      this.contentPreview.show = true;
    },

    closeSendAsDialog() {
      this.currentSelected = [];
      this.setAllEntitiesFilteredToApply([]);
      this.showApplySendAsDialog = false;
    },

    closeCalendarsDialog() {
      this.currentSelected = [];
      this.setAllEntitiesFilteredToApply([]);
      this.showCalendarsDialog = false;
    },

    clear() {
      this.$router.replace({
        name: "ApplySignTemplates",
      });
    },

    async closeVacationApplyActions(get_users = false) {
      this.currentSelected = [];
      this.setAllEntitiesFilteredToApply([]);
      this.showVacationDialog = false;

      if (this.templateKey) {
        this.clear();
      }

      if (get_users) {
        await this.getUsersTable();
      }
    },

    async deleteUserVacation(user_email = false) {
      this.showLoading = true;

      const emails = user_email ? [user_email] : this.selectedUsersWithVacation;

      const payload = {
        emails: emails,
        vacation_template_key: "",
        vacation: {
          settings: {
            enable_auto_reply: false,
            response_subject: "",
            response_body_plain_text: null,
            response_body_html: "",
            restrict_to_contacts: false,
            restrict_to_domain: false,
            start_time: null,
            end_time: null,
          },
        },
        is_delete: true,
      };

      await this.updateVacationInBatch(payload);
      this.currentSelected = [];
      this.setAllEntitiesFilteredToApply([]);
      await this.getUsersTable();

      this.showLoading = false;
    },

    closeRestrictedDialog() {
      this.showRestrictedDialog = false;
      this.currentSelected = [];
      this.setAllEntitiesFilteredToApply([]);
    },

    closeRequestChangeTagsDialog() {
      this.showRequestChangeTags = false;
      this.userToRequestInfoChange = "";
    },

    openRequestChangeTagsDialog() {
      this.showRequestChangeTags = true;
    },

    sendUserTagsUpdateRequest(userEmail = false) {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/send-tags-update`;
      const payload = {
        email_list: userEmail || this.emailsFromSelectedUsers,
      };
      const auth = {
        headers: {
          Authorization: this.token,
        },
      };
      this.$axios
        .post(url, payload, auth)
        .then(() => {
          this.setSnackBar({
            message: this.$t("successMessages.requestSent"),
            show: true,
          });
        })
        .catch((error) => {
          this.setSnackBar({
            message: "Erro ao solicitar atualização das informações",
            show: true,
            color: "error",
          });
          console.error("sendUserTagsUpdateRequest: ", error);
        })
        .finally(() => {
          this.loading = false;
          this.closeRequestChangeTagsDialog();
        });
    },

    turnRestrictedUser() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/user/update`;
      const user_is_editable = !this.currentSelected[0].is_editable;
      const payload = {
        id_user: this.currentSelected[0].id_google,
        editable_data: { is_editable: user_is_editable },
      };
      const auth = {
        headers: {
          Authorization: this.token,
        },
      };
      this.$axios
        .post(url, payload, auth)
        .then(async () => {
          this.setSnackBar({
            message: "Usuário atualizado com sucesso",
            show: true,
          });
          this.getUsersTable();
        })
        .catch((err) => {
          this.setSnackBar({
            message: "Erro ao atualizar o usuário",
            show: true,
            color: "error",
          });
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
          this.closeRestrictedDialog();
        });
    },

    setSearch(search_term) {
      let formatedSearch = [];

      if (search_term) {
        formatedSearch = setSearchByString(search_term, this.filtersToSearch);
        const currentFilters = this.currentFilters.filter(
          ({ value }) => !this.filtersToSearch.includes(value)
        );
        formatedSearch = [...currentFilters, ...formatedSearch];
        this.updateFilters(formatedSearch);
      } else {
        this.clearTextSearch();
      }
    },

    clearTextSearch() {
      this.currentFilters = this.currentFilters.filter(
        ({ value }) => !this.filtersToSearch.includes(value)
      );

      this.updateFilters(this.currentFilters);
    },

    removeFilter(filter) {
      const index = this.currentFilters.findIndex(
        (item) => item.search === filter.search
      );
      this.currentFilters.splice(index, 1);

      this.updateFilters(this.currentFilters);
    },

    updateFilters(value) {
      this.currentFilters = updateAppiledFiltersValues(
        this.currentFilters,
        value,
        this.filtersToSearch
      );

      this.pagination.page = 1;
      this.getUsersTable();
    },

    async getUsersTable() {
      this.showLoading = true;

      const queryParams = getPaginationDataFromApi(
        this.pagination,
        this.hasFiltersToApply,
        this.currentFilters,
        this.currentOffset
      );

      if (this.showSendAsEmails || !this.displayOnlyPrimaryEmails) {
        await this.getSendAsByTablePage(queryParams).then(({ data }) => {
          this.allUsers = data.sendas;
          this.totalUsers = data.count;
          this.setAllEntitiesFiltered(data.all_entities);
          this.currentAllEmailsTypes = data.count;
        });
      } else {
        await this.getUsersByTablePage(queryParams).then(({ data }) => {
          localStorage.setItem("users", JSON.stringify(data.users));
          this.allUsers = data.users;
          this.totalUsers = data.count;
          this.setAllEntitiesFiltered(data.all_entities);
          this.currentPrimaryEmails = data.count;
          for (const updatedUser of this.allUsers) {
            const index = this.users.findIndex(
              (user) => user.key === updatedUser.key
            );

            if (index !== -1) {
              this.users[index] = updatedUser;
            }
          }
          this.setUsers(this.users);
        });
      }

      this.showLoading = false;
    },

    async syncUsersAndUOs() {
      this.showLoading = true;
      this.loadingSyncUsersAndUOs = true;
      await this.syncUsers().then(async () => {
        await this.getUsersTable();
        localStorage.setItem("users", JSON.stringify(this.allUsers));
        await this.syncUos();
      });
      this.showLoading = false;
      this.loadingSyncUsersAndUOs = false;
    },

    async syncSendAsEmails() {
      this.showLoading = true;
      this.loadingSyncSendAs = true;
      await this.syncSendAs();
      await this.getUsersTable();
      this.showLoading = false;
      this.loadingSyncSendAs = false;
    },

    resetFilters() {
      this.currentFilters = [];
    },

    editUser(user) {
      this.selectedUser = user;
      this.showUserCardSignature = true;
    },

    execute(key, item) {
      if (key === "email") {
        if (!this.currentSelected.find((user) => user.email === item.email)) {
          this.currentSelected.push(item);
        }
        this.selectSignatureModel = true;
      } else if (key === "vacation") {
        if (!this.currentSelected.find((user) => user.email === item.email)) {
          this.currentSelected.push(item);
        }
        this.showVacationDialog = true;
      } else if (key === "tags") {
        this.editUser(item);
      } else if (key === "request_tags_update") {
        this.userToRequestInfoChange = item.email;
        this.showRequestChangeTags = true;
      } else if (key === "restricted") {
        if (!this.currentSelected.find((user) => user.email === item.email)) {
          this.currentSelected.push(item);
        }
        this.showRestrictedDialog = true;
      }
    },

    addMultipleItens() {
      if (!this.allEntitiesFilteredToApply.length) {
        return;
      }

      let entitiesToApply = [...this.allEntitiesFilteredToApply];

      this.allItemsInThePage.forEach((value) => {
        const index = entitiesToApply.findIndex((entity) =>
          value.id
            ? value.id === entity.id
            : value.id_google === entity.id_google
        );
        if (index === -1) {
          entitiesToApply.push(value);
        }
      });

      this.setAllEntitiesFilteredToApply(entitiesToApply);
    },

    getPageSelection(event) {
      this.setAllItensSelected(event.value);

      if (event.value) {
        this.allItemsInThePage = event.items;
        if (this.allEntitiesFilteredToApply.length) {
          this.addMultipleItens();
        }
      } else {
        this.allItemsInThePage = [];
        this.currentSelected = [];
        this.setAllEntitiesFilteredToApply([]);
      }
    },

    getSignatureModelName(signature_id) {
      let search = this.allTemplates.find(
        (template) => template.id === signature_id
      );

      return search ? search.name : false;
    },

    getVacationTemplateName(template_id) {
      const search = this.allVacationTemplates.find(
        (template) => template.key === template_id
      );

      return search ? search.name : "";
    },

    async loadData() {
      if (this.sendAsEmails.length === 0 && this.showSendAsEmails) {
        this.loadingSyncSendAs = true;
        await this.syncSendAsEmails();
        this.loadingSyncSendAs = false;
      }
    },

    async setVacationTemplateToPreview(key) {
      this.contentPreview.template = await this.allVacationTemplates.find(
        (template) => template.key === key
      );
    },

    async setSignatureTemplateToPreview(id) {
      this.contentPreview.template = await this.allTemplates.find(
        (template) => template.id === id
      );
    },

    async selectVacationTemplate() {
      this.vacationTemplate = await this.allVacationTemplates.find(
        (template) => template.key === this.$route.query.key
      );

      if (!this.vacationTemplate) {
        this.snackbarText = errorMessages.select_template_to_apply;
        this.showSnackbar = true;
        this.snackbarType = "error";
        setTimeout(() => this.$router.push("/sign/vacation-messages"), 3000);
      }
    },

    async selectTemplateToApply(id) {
      this.selectedTemplate = this.allTemplates.find(
        (template) => template.id === id
      );
      if (this.selectedTemplate) {
        this.steps++;
      } else {
        this.snackbarText = errorMessages.select_template_to_apply;
        this.showSnackbar = true;
        this.snackbarType = "error";
        setTimeout(() => this.$router.push("/sign/template"), 3000);
      }
    },

    tempUpdateSignature(payload) {
      let { emails, template_key } = payload;
      let itemsMapper = this.showSendAsEmails
        ? (sendas) => {
            let entries = Object.entries(emails);
            if (
              entries.find(
                ([key, value]) =>
                  key == sendas.user.id_google && value.includes(sendas.email)
              )
            ) {
              sendas.applied_signature_key = template_key;
            }
            return sendas;
          }
        : (user) => {
            if (emails.includes(user.email)) {
              user.applied_signature_key = template_key;
            }
            return user;
          };
      this.usersInTable = this.usersInTable.map(itemsMapper);
    },

    async close(hasSaved) {
      if (hasSaved) {
        await this.getUsersTable();
      }
      this.showUserCardSignature = false;
      this.selectedUser = {};
    },

    setDefaultVacationSettings() {
      const defaultVacationSettings = {
        loading: false,
        settings: {
          enable_auto_reply: false,
          response_subject: null,
          response_body_plain_text: null,
          response_body_html: null,
          restrict_to_contacts: false,
          restrict_to_domain: false,
          start_time: null,
          end_time: null,
        },
      };
      this.vacation = defaultVacationSettings;
      this.vacationTemplate = null;
    },

    paginationChangeHandler(value) {
      this.pagination = value;
      this.getUsersTable();
    },

    setUsersTableHeaderColumns() {
      const localStorageHeaders =
        JSON.parse(localStorage.getItem("customUserTableCols")) || null;

      if (!localStorageHeaders) {
        localStorage.setItem(
          "customUserTableCols",
          JSON.stringify(usersTableHeader)
        );
      }
    },
  },

  async beforeMount() {
    this.loading = true;

    this.setUsersTableHeaderColumns();

    if (this.hasSignPermission && this.hasConectaSign) {
      if (this.allTemplates.length === 0) {
        await Promise.all([this.getSignatureTemplates()]);
      }
      if (this.allVacationTemplates.length === 0) {
        await Promise.all([this.getVacationTemplates()]);
      }

      if (this.templateId) {
        this.selectTemplateToApply(this.$route.query.id);
      }

      if (this.templateKey) {
        this.selectVacationTemplate();
      }
    }

    this.showSendAsEmails = !this.displayOnlyPrimaryEmails;

    this.setDefaultVacationSettings();

    await this.loadData();

    setSignatureFeedbackPage();
    this.loading = false;
  },
};
</script>
<style>
.sign-permission-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
}

.sign-plan-text {
  background-color: var(--v-primarySign-base);
  border-radius: 5px;
  padding: 10px;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  width: max-content;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
}

.sign-permission-text {
  background-color: var(--v-primarySuite-base);
  color: white;
  border-radius: 5px;
  padding: 10px;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  position: absolute;
  width: max-content;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
}

.hide-table .v-data-table__wrapper,
.hide-table .v-data-footer {
  display: none;
}

.sign-table tbody tr.v-data-table__selected {
  background-color: #e3f2fd !important;
}

.px-0 > .col-sm-3 > .col-md-3 > .col-3 {
  padding: 0;
}

/* Reference https://codepen.io/Astroop/pen/LYpWjMJ */

.uk-timeline .uk-timeline-item .uk-card {
  max-height: 300px;
}

.uk-timeline .uk-timeline-item {
  display: flex;
  position: relative;
}

.uk-timeline .uk-timeline-item::before {
  background: #dadee4;
  content: "";
  height: 100%;
  left: 12px;
  position: absolute;
  top: 24px;
  width: 1px;
  z-index: 1;
}

.uk-timeline .uk-timeline-item:last-of-type::before {
  background: #dadee4;
  content: "";
  height: 100%;
  left: 12px;
  position: absolute;
  top: 24px;
  width: 0px;
  z-index: 1;
}

.uk-badge {
  background: var(--v-primary-base);
  border-radius: 50%;
  padding: 3px 8px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: bold;
}

.uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  font-family: "Roboto";
}

.uk-timeline .uk-timeline-item .uk-timeline-content {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0 0 0 1rem;
  font-family: "Roboto";
}

.custom-alert {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.content-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
}
</style>
