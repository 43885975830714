var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fullscreen":"","hide-overlay":"","height":"100%","scrollable":"","transition":"dialog-bottom-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.handleClose.apply(null, arguments)},"click:outside":_vm.handleClose},model:{value:(_vm.showHideDialog),callback:function ($$v) {_vm.showHideDialog=$$v},expression:"showHideDialog"}},[_c('v-card',{staticClass:"lighten-3 elevation-0",attrs:{"tile":"","flat":""}},[_c('v-app-bar',{staticStyle:{"z-index":"999"},attrs:{"absolute":!_vm.$vuetify.breakpoint.mobile,"app":_vm.$vuetify.breakpoint.mobile,"height":"80","color":"primary","dark":"","flat":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.handleClose}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1),_c('v-app-bar-title',[_vm._v(" "+_vm._s(_vm.$t("applySignature.applyVacationMessage"))+" ")])],1),_c('v-stepper',{key:_vm.mobile,staticClass:"px-0 mx-0 mt-12 pt-8 overflow-y-auto",attrs:{"disabled":_vm.loadingSign,"flat":"","tile":"","vertical":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-row',{class:`${
          _vm.$vuetify.breakpoint.smAndDown ? 'mt-5' : 'primary'
        } ma-0 pa-0`,attrs:{"align":"end","no-gutters":""}},_vm._l((_vm.stepNames),function(step){return _c('v-col',{key:step.id},[_c('v-stepper-step',{class:`${
              _vm.currentStep === step.id ? 'background rounded-t-lg' : ''
            } py-4 ${_vm.canClickInThisStep(step.id) ? 'link' : ''}`,attrs:{"complete":_vm.currentStep > step.id,"step":step.id},on:{"click":function($event){return _vm.clickInThisStep(step.id)}}},[_c('div',{class:`${
                _vm.currentStep === step.id ? 'primary--text' : 'white--text'
              } pl-2 font-weight-medium`},[_vm._v(" "+_vm._s(step.title)+" ")]),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{class:`${
                _vm.currentStep === step.id ? 'primary--text' : 'white--text'
              } pl-2 caption mt-1`,staticStyle:{"line-height":"14px"}},[_vm._v(" "+_vm._s(step.subtitle)+" ")]):_vm._e()])],1)}),1):_vm._e(),(_vm.currentStep === 5 && _vm.hasUsersWithVacation)?_c('v-row',{staticClass:"mx-10"},[_c('Alert',{staticClass:"mt-5",staticStyle:{"width":"100%"},attrs:{"color":"warning","text":"","dense":"","expansion":"","action-text":"Ver informações","action-icon":"mdi-account-multiple"},scopedSlots:_vm._u([{key:"expansion",fn:function(){return [_c('ul',_vm._l((_vm.selectedUsesWithVacationSettings),function(user){return _c('li',{key:user.email},[_vm._v(" "+_vm._s(user.email)+" ")])}),0)]},proxy:true}],null,false,2738136350)},[_c('span',[_vm._v(_vm._s(_vm.$t("vacationApply.overwriteWarning"))+" ")])])],1):_vm._e(),(_vm.mobile)?_c('div',{staticClass:"mt-8 background fill-height"},_vm._l((_vm.stepNames),function(step,i){return _c('div',{key:step.id},[_c('v-stepper-step',{class:`${
              _vm.currentStep === step.id ? 'background rounded-t-lg' : ''
            } py-4 ${_vm.canClickInThisStep(step.id) ? 'link' : ''}`,attrs:{"complete":_vm.currentStep > step.id,"step":step.id},on:{"click":function($event){return _vm.clickInThisStep(step.id)}}},[_c('div',{class:`${
                _vm.currentStep === step.id ? 'primary--text' : 'white--text'
              } text-none pl-2 font-weight-medium`},[_vm._v(" "+_vm._s(step.title)+" ")]),_c('div',{class:`${
                _vm.currentStep === step.id ? 'primary--text' : 'white--text'
              } pl-2 caption font-weight-regular`},[_vm._v(" "+_vm._s(step.subtitle)+" ")]),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"pl-2 caption mt-1",staticStyle:{"line-height":"14px"}},[_vm._v(" "+_vm._s(step.subtitle)+" ")]):_vm._e()]),_c('v-stepper-content',{staticClass:"px-0 mx-0",attrs:{"step":step.id}},[_c('v-card',{key:_vm.currentStep,staticClass:"overflow-y-auto px-2",attrs:{"flat":"","tile":""}},[(step.id === 1)?_c('StartedVacationFlow',{attrs:{"show":step.id === 1},on:{"started":function($event){_vm.currentStep = 2}}}):_vm._e(),(step.id === 2)?_c('TemplateSelector',{attrs:{"show":step.id === 2,"vacation":""},on:{"html":_vm.setTemplate,"clickedTemplate":_vm.setSelectedTemplate}}):(step.id === 3)?_c('VacationSettings',{attrs:{"name":_vm.selectedTemplateName,"html":_vm.htmlTemplate,"subject":_vm.subjectTemplate},on:{"vacationSettings":_vm.setVacationSettings}}):(step.id === 4)?_c('AbsenseSettings',{attrs:{"name":_vm.selectedTemplateName,"html":_vm.htmlTemplate,"subject":_vm.subjectTemplate},on:{"vacationSettings":function($event){_vm.setVacationSettings($event);
                  _vm.currentStep = 5;}}}):(step.id === 5)?_c('ConfirmTemplateApply',{attrs:{"template-subject":_vm.subjectTemplate,"template-name":_vm.selectedTemplateName,"template-html":_vm.htmlTemplate,"selected-users":_vm.autocompleteSelected},on:{"change:selected-users":function($event){_vm.autocompleteSelected = $event},"skip":function($event){_vm.currentStep = 4},"apply":_vm.applyVacation}}):(step.id === 6)?_c('ManageUserAccess',{attrs:{"vacation-settings":_vm.settings || {},"selected-users":_vm.autocompleteSelected},on:{"change:selected-users":function($event){_vm.autocompleteSelected = $event},"input:vacation-settings":function($event){_vm.settings = $event},"finish":_vm.finish}}):_vm._e()],1)],1),(i < _vm.stepNames.length - 1)?_c('v-divider',{staticClass:"mb-3"}):_vm._e()],1)}),0):_vm._e(),(!_vm.mobile)?_c('v-stepper-items',{staticClass:"background overflow-y-auto"},_vm._l((_vm.stepNames),function(step){return _c('v-stepper-content',{key:step.id,attrs:{"step":step.id}},[(step.id === 1)?_c('StartedVacationFlow',{attrs:{"show":step.id === 1},on:{"started":function($event){_vm.currentStep = 2}}}):(step.id === 2)?_c('TemplateSelector',{attrs:{"show":step.id === 2,"vacation":""},on:{"html":_vm.setTemplate,"clickedTemplate":_vm.setSelectedTemplate}}):(step.id === 3)?_c('VacationSettings',{attrs:{"name":_vm.selectedTemplateName,"html":_vm.htmlTemplate,"subject":_vm.subjectTemplate},on:{"vacationSettings":function($event){_vm.setVacationSettings($event);
              _vm.currentStep = 4;}}}):(step.id === 4)?_c('AbsenseSettings',{attrs:{"name":_vm.selectedTemplateName,"html":_vm.htmlTemplate,"subject":_vm.subjectTemplate},on:{"vacationSettings":function($event){_vm.setVacationSettings($event);
              _vm.currentStep = 5;}}}):(step.id === 5)?_c('ConfirmTemplateApply',{attrs:{"template-subject":_vm.subjectTemplate,"templateName":_vm.selectedTemplateName,"template-html":_vm.htmlTemplate,"selected-users":_vm.autocompleteSelected},on:{"change:selected-users":function($event){_vm.autocompleteSelected = $event},"apply":_vm.applyVacation,"skip":function($event){_vm.currentStep = 6}}}):(step.id === 6)?_c('ManageUserAccess',{attrs:{"vacation-settings":_vm.settings || {},"selected-users":_vm.autocompleteSelected},on:{"change:selected-users":function($event){_vm.autocompleteSelected = $event},"input:vacation-settings":function($event){_vm.settings = $event},"finish":_vm.finish}}):_vm._e()],1)}),1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }