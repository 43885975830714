<template>
  <div>
    <v-toolbar
      :class="`px-0 rounded-b-0 hide-toolbar-default-padding py-0 ${
        $vuetify.breakpoint.xs ? 'd-flex flex-column' : 'pr-4'
      }`"
      flat
      height="auto"
    >
      <v-row
        no-gutters
        class="pa-0 ma-0 d-flex justify-space-between"
        align="end"
        style="width: 100%"
      >
        <v-col
          v-if="$vuetify.breakpoint.lgAndUp"
          cols="12"
          lg="6"
          xl="6"
          class="pa-0 ma-0"
        >
          <v-tabs
            v-model="selectedTab"
            fixed-tabs
            class="ma-0"
            slider-size="4"
            color="accentSuite"
            active-class="font-weight-bold active-tab"
          >
            <v-tab class="text-body-1 font-weight-medium text-none ma-0">
              {{ $t("usersPage.primaryEmails") }}
              <v-chip
                v-if="totalUsersNumber > 0 && !loading"
                class="mx-2 font-weight-bold"
                color="grey"
                small
                dark
                pill
                >{{ totalUsersNumber }}</v-chip
              >
              <v-progress-circular
                v-if="loading && selectedTab === 0"
                indeterminate
                color="primary"
                size="18"
                width="3"
                class="mx-3"
              ></v-progress-circular>
            </v-tab>
            <v-tab
              class="text-body-1 font-weight-medium text-none ma-0"
              :disabled="!!templateKey"
            >
              {{ $t("usersPage.allEmails") }}
              <v-chip
                v-if="totalSecondaryNumber > 0 && !loading"
                class="mx-2 font-weight-bold"
                color="grey"
                small
                dark
                pill
                >{{ totalSecondaryNumber }}</v-chip
              >
              <v-progress-circular
                v-if="loading && selectedTab === 1"
                indeterminate
                color="primary"
                size="18"
                width="3"
                class="mx-3"
              ></v-progress-circular>
            </v-tab>
          </v-tabs>
        </v-col>
        <v-col
          v-else
          cols="12"
          md="5"
          sm="4"
          xs="6"
          :class="$vuetify.breakpoint.xs ? 'px-3 pb-2' : 'pr-2'"
        >
          <EmailsTypesSelector @update="selectedTab = $event" />
        </v-col>
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="6"
          :class="`pa-0 ma-0 pb-1 ${
            $vuetify.breakpoint.xs ? 'px-3' : 'pl-4'
          } d-flex justify-end`"
        >
          <div v-show="displayOnlyPrimaryEmails && selectedNumber === 0">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  style="height: 44px; width: 30px"
                  class="pa-0 elevation-0m mr-2"
                >
                  <v-icon v-text="'mdi-dots-horizontal'" size="24" />
                </v-btn>
              </template>
              <v-list>
                <div v-for="(item, index) in generalActions" :key="index">
                  <v-list-item
                    link
                    @click="item.action"
                    :disabled="item.disabled"
                    v-if="item.show"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-action
                      v-if="item.beta"
                      class="my-0"
                      style="min-width: auto !important"
                    >
                      <v-chip
                        color="success"
                        dark
                        :title="$t('common.availableIndefinitely')"
                        x-small
                        class="font-weight-medium"
                        >Beta
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </div>
          <v-btn
            v-show="!displayOnlyPrimaryEmails && selectedNumber === 0"
            elevation="3"
            outlined
            color="primary"
            class="text-none text-body-1 font-weight-medium px-8 mt-0 mr-2 elevation-0"
            large
            :dark="!loadingDownloadSendAs"
            @click="downloadSendAsEmails"
            :loading="loadingDownloadSendAs"
            :disabled="loadingDownloadSendAs"
          >
            <v-icon left v-text="'mdi-download'" />
            {{ $t("action.download") }}
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-show="selectedNumber === 0"
                outlined
                color="primary"
                large
                dark
                class="text-none text-body-1 font-weight-medium px-8 mt-0 mr-2 elevation-0"
              >
                <v-icon left v-text="'mdi-plus'" size="24" class="mr-2" />
                {{ $t("action.create") }}
              </v-btn>
            </template>
            <v-list>
              <div v-for="(item, index) in createActions" :key="index">
                <v-list-item
                  link
                  @click="item.action"
                  :disabled="item.disabled"
                  v-if="item.show"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-action v-if="item.beta">
                    <div>
                      <v-chip
                        color="success"
                        dark
                        :title="$t('common.availableIndefinitely')"
                        small
                        class="font-weight-medium"
                        >Beta
                      </v-chip>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>

          <v-btn
            v-if="selectedNumber > 0"
            :block="$vuetify.breakpoint.xs"
            color="secondary lighten-2"
            :text="$vuetify.breakpoint.lgAndUp"
            large
            tile
            :icon="$vuetify.breakpoint.mdAndDown"
            :class="`text-none text-body-1 font-weight-medium px-3 py-4 ${
              $vuetify.breakpoint.xs ? '' : 'mr-4'
            }`"
            @click="$emit('select:clear')"
          >
            <v-icon v-if="$vuetify.breakpoint.mdAndDown" v-text="'mdi-close'" />
            {{ $vuetify.breakpoint.lgAndUp ? $t("action.clear") : "" }}
          </v-btn>
          <UsersBulkActionsMenu
            v-if="!hasTemplateSelected"
            :disableForUsersWithoutVacationMessage="
              disableForUsersWithoutVacationMessage
            "
            :hide-checkbox="$vuetify.breakpoint.smAndDown"
            :total-users-number="totalEmails"
            :selected-number="selected"
            :loading="loading"
            :itens-per-page="itensPerPage"
            @apply:signature="$emit('apply:signature')"
            @apply:signature-to-secondary="
              $emit('apply:signature-to-secondary')
            "
            @apply:vacation-message="$emit('apply:vacation-message')"
            @create:secondary-emails="$emit('create:secondary-emails')"
            @remove:vacation-message="$emit('remove:vacation-message')"
            @select:all="$emit('select:all')"
            @select:clear="$emit('select:clear')"
            @update:user-tags="$emit('update:user-tags')"
            @insert:users-calendar="$emit('insert:users-calendar')"
          />

          <v-btn
            v-if="hasTemplateSelected"
            :disabled="selectedNumber === 0"
            color="accent"
            large
            :dark="selectedNumber > 0"
            class="text-none text-body-1 font-weight-medium px-6 py-4 ml-4 elevation-0"
            @click="
              $emit(templateKey ? 'apply:vacation-message' : 'apply:signature')
            "
          >
            {{
              templateKey
                ? $t("applySignature.applyVacationMessage")
                : $t("applySignature.applyEmailSignature")
            }}
            {{
              selectedNumber > 0
                ? `${$t("common.toThe")} ${selectedNumber} ${
                    selectedNumber === 1
                      ? $t("common.selected")
                      : $t("common.selecteds")
                  }`
                : ""
            }}
            <v-icon
              right
              :disabled="selectedNumber === 0"
              v-text="'mdi-arrow-right'"
              class="arrow-next ml-2"
              size="24"
            />
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

import UsersBulkActionsMenu from "@/components/sign/users/UsersBulkActionsMenu";
import EmailsTypesSelector from "@/components/sign/users/EmailsTypesSelector.vue";

import { logBtnEvent } from "@/helpers/services/registerAnalytics";
import { saveAs } from "file-saver";
import { todayDataBaseFormat } from "@/helpers/services/utils";

export default {
  name: "FilterEmailsTabs",

  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    selectedNumber: { type: Number, default: 0 },
    totalUsersNumber: { type: Number, default: 0 },
    totalSecondaryNumber: { type: Number, default: 0 },
    itensPerPage: { type: Number, default: 50 },
    disableForUsersWithoutVacationMessage: { type: Boolean, default: true },
  },

  components: {
    UsersBulkActionsMenu,
    EmailsTypesSelector,
  },

  data() {
    return {
      currentTab: 0,
      ver1: true,
      loadingDownloadSendAs: false,
    };
  },

  computed: {
    ...mapGetters([
      "displayOnlyPrimaryEmails",
      "allVacationTemplates",
      "allTemplates",
      "hasSignPermission",
      "companyHasSignActive",
      "token",
    ]),

    generalActions() {
      return [
        {
          title: this.$t("action.updateUsersViaCSV"),
          action: this.openCSV,
          disabled: !this.companyHasSignActive || !this.hasSignPermission,
          show: this.displayOnlyPrimaryEmails,
        },
      ];
    },

    createActions() {
      return [
        {
          title: this.$t("applySignature.newUser"),
          action: this.goToOnboarding,
          disabled: false,
          show: this.displayOnlyPrimaryEmails,
        },
        {
          title: this.$t("action.newWorkspaceGroup"),
          action: this.openCreateGroup,
          disabled: false,
          show: this.displayOnlyPrimaryEmails,
        },
        {
          title: this.$t("action.transferFilesFromDrive"),
          beta: true,
          action: () => this.$router.push({ name: "DriveMigration" }),
          disabled: false,
          show: this.displayOnlyPrimaryEmails,
        },
        {
          title: this.$t("action.migrateEmails"),
          beta: true,
          action: () => this.$router.push({ name: "EmailMigration" }),
          disabled: false,
          show: this.displayOnlyPrimaryEmails,
        },
      ];
    },

    selected() {
      return this.selectedNumber;
    },

    totalEmails() {
      if (this.displayOnlyPrimaryEmails) {
        return this.totalUsersNumber;
      }
      return this.totalSecondaryNumber;
    },

    selectedTab: {
      get() {
        return this.displayOnlyPrimaryEmails ? 0 : 1;
      },
      set(value) {
        this.setDisplayPrimaryEmails(value === 1 ? false : true);
      },
    },

    templateKey() {
      return this.$route.query.key;
    },

    templateId() {
      return this.$route.query.id;
    },

    hasTemplateSelected() {
      return this.templateKey || this.templateId ? true : false;
    },
  },

  watch: {
    "$route.query.key"() {
      if (this.templateKey) {
        this.selectVacationTemplate();
      }
    },

    selectedTab() {
      this.$emit("select:clear");
    },

    displayOnlyPrimaryEmails() {
      if (!this.displayOnlyPrimaryEmails) {
        logBtnEvent({
          label: "Mostrar e-mails secundários",
          action: "show_send_as",
        });
      }
    },
  },

  methods: {
    ...mapMutations(["setDisplayPrimaryEmails", "setSnackBar"]),

    goToOnboarding() {
      this.$router.push({ path: "/onboarding-new-user" });
    },

    openCSV() {
      this.$emit("update:users-csv");
    },

    openCreateGroup() {
      this.$emit("openCreateGroup");
    },

    downloadSendAsEmails() {
      this.loadingDownloadSendAs = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/download-sendas`;

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      this.$axios(url, auth)
        .then(({ data }) => {
          const blob = new Blob([data], {
            type: "application/csvcharset=" + this.encoding,
          });

          saveAs(blob, `sendas-${todayDataBaseFormat}.csv`);

          this.setSnackBar({
            message: "Lista de e-mails salva com sucesso",
            show: true,
            color: "success",
          });
        })
        .catch((error) => {
          this.setSnackBar({
            message: "Ocorreu um erro durante o download do arquivo",
            show: true,
            color: "error",
          });
          console.error("downloadSendAsEmails()", error);
        })
        .finally(() => (this.loadingDownloadSendAs = false));
    },
  },
};
</script>

<style scoped>
.active-tab > span {
  background: #eb5c1d !important;
}
</style>
