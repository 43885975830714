<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    height="100%"
    scrollable
    v-resize="onResize"
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
  >
    <v-app-bar
      :absolute="!$vuetify.breakpoint.mobile"
      :app="$vuetify.breakpoint.mobile"
      height="80"
      color="primary"
      dark
      flat
      style="z-index: 999"
    >
      <v-btn icon dark @click="closeDialog()">
        <v-icon v-text="'mdi-close'" />
      </v-btn>
      <v-app-bar-title class="text-no-wrap"> Criar novo grupo </v-app-bar-title>
    </v-app-bar>

    <v-stepper
      v-model="currentStep"
      :key="mobile"
      flat
      tile
      :vertical="mobile"
      class="px-0 mx-0 mt-12 pt-8 overflow-y-auto"
    >
      <!-- MOBILE -->
      <div v-if="mobile" class="mt-8 background fill-height">
        <div v-for="(step, i) in stepNames" :key="step.id">
          <v-stepper-step
            :class="`${
              currentStep === step.id ? 'background rounded-t-lg' : ''
            } ${step.disabled ? 'disabled' : ''} py-4`"
            :step="step.id"
            @click="cantChangeStep(step) ? null : (currentStep = step.id)"
          >
            <div :class="`primary--text text-none pl-2 font-weight-medium`">
              {{ step.title }}
            </div>
            <div :class="`primary--text pl-2 caption font-weight-regular`">
              {{ step.subtitle }}
            </div>
            <v-chip
              class="ml-2 mt-2"
              color="success"
              v-if="step.disabled"
              x-small
              >Em breve</v-chip
            >
            <div
              v-if="!$vuetify.breakpoint.mobile"
              class="pl-2 caption mt-1"
              style="line-height: 14px"
            >
              {{ step.subtitle }}
            </div>
          </v-stepper-step>
          <v-stepper-content :step="step.id" class="px-0 mx-0">
            <v-card flat tile class="overflow-y-auto px-2" :key="currentStep">
              <groupsIntro
                v-if="currentStep === 1 && currentStep === step.id"
                @started="currentStep = 2"
              />
              <groupsForm
                v-if="currentStep === 2 && currentStep === step.id"
                @back="currentStep = 1"
                @next="currentStep = 3"
                @updateGroupId="updateGroup"
              />
              <groupsSettings
                v-if="currentStep === 3 && currentStep === step.id"
                :group="group"
                @back="currentStep = 2"
                @next="currentStep = 4"
              />
              <groupsMembers
                v-if="currentStep === 4 && currentStep === step.id"
                :group-members="groupMembers"
                :group="group"
                @back="currentStep = 3"
                @addMember="addMember"
                @updateMember="updateMember"
                @deleteMember="deleteMember"
                @next="currentStep = 5"
              />
              <groupsSharedDrives
                v-if="currentStep === 5 && currentStep === step.id"
                :group="group"
                :groupDrives="groupDrives"
                :loadingScopes="loadingScopes"
                :loadingDrives="loadingDrives"
                :disabled="drivesDisabled"
                @back="currentStep = 4"
                @close="closeDialog()"
                @addGroupDrive="addGroupDrive"
                @updateGroupDrives="updateGroupDrives"
                @deleteGroupDrives="deleteGroupDrives"
              />
            </v-card>
          </v-stepper-content>
          <v-divider v-if="i < stepNames.length - 1" class="mb-3" />
        </div>
      </div>

      <!-- DESKTOP -->
      <div v-if="!mobile">
        <v-row
          :class="`${
            $vuetify.breakpoint.smAndDown ? 'mt-5' : 'primary'
          } ma-0 pa-0`"
          no-gutters
        >
          <v-col v-for="step in stepNames" :key="step.id">
            <v-stepper-step
              :class="`${
                currentStep === step.id ? 'background rounded-t-lg' : ''
              }  ${
                step.disabled ? 'disabled' : cantChangeStep(step) ? '' : 'link'
              } py-4`"
              :complete="currentStep > step.id"
              :step="step.id"
              style="height: 100%"
              @click="cantChangeStep(step) ? null : (currentStep = step.id)"
            >
              <div
                :class="`${
                  currentStep === step.id ? 'primary--text' : 'white--text'
                } pl-2 font-weight-medium`"
              >
                {{ step.title }}
              </div>
              <div
                v-if="!$vuetify.breakpoint.mobile"
                :class="`${
                  currentStep === step.id ? 'primary--text' : 'white--text'
                } pl-2 caption mt-1`"
                style="line-height: 14px"
              >
                {{ step.subtitle }}
              </div>
              <v-chip
                class="ml-2 mt-2"
                color="success"
                v-if="step.disabled"
                x-small
                >Em breve</v-chip
              >
            </v-stepper-step>
          </v-col>
        </v-row>
        <v-stepper-items v-for="step in stepNames" :key="step.id">
          <v-stepper-content :key="step.id" :step="step.id">
            <groupsIntro
              v-if="currentStep === 1 && currentStep === step.id"
              @started="currentStep = 2"
            />
            <groupsForm
              v-if="currentStep === 2 && currentStep === step.id"
              @back="currentStep = 1"
              @next="currentStep = 3"
              @updateGroupId="updateGroup"
            />
            <groupsSettings
              v-if="currentStep === 3 && currentStep === step.id"
              :group="group"
              :groupTypeSelected="groupTypeSelected"
              :groupConfig="groupConfig"
              @updateGroupConfig="groupConfig = $event"
              @updateGroupType="groupTypeSelected = $event"
              @back="currentStep = 2"
              @next="currentStep = 4"
            />
            <groupsMembers
              v-if="currentStep === 4 && currentStep === step.id"
              :group-members="groupMembers"
              :group="group"
              @back="currentStep = 3"
              @addMember="addMember"
              @updateMember="updateMember"
              @deleteMember="deleteMember"
              @next="currentStep = 5"
            />
            <groupsSharedDrives
              v-if="currentStep === 5 && currentStep === step.id"
              :group="group"
              :groupDrives="groupDrives"
              :loadingScopes="loadingScopes"
              :loadingDrives="loadingDrives"
              :disabled="drivesDisabled"
              @back="currentStep = 4"
              @close="closeDialog()"
              @addGroupDrive="addGroupDrive"
              @updateGroupDrives="updateGroupDrives"
              @deleteGroupDrives="deleteGroupDrives"
            />
          </v-stepper-content>
        </v-stepper-items>
      </div>
    </v-stepper>
  </v-dialog>
</template>

<script>
import groupsIntro from "./steps/groupsIntro.vue";
import groupsForm from "./steps/groupsForm.vue";
import groupsSettings from "./steps/groupsSettings.vue";
import groupsMembers from "./steps/groupsMembers.vue";
import groupsSharedDrives from "./steps/groupsSharedDrives.vue";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CreateGroups",
  components: {
    groupsIntro,
    groupsForm,
    groupsSettings,
    groupsMembers,
    groupsSharedDrives,
  },

  props: {
    show: { type: Boolean, default: false },
  },

  data() {
    return {
      currentStep: 1,
      group: {},
      groupTypeSelected: "public",
      groupConfig: {
        whoCanContactOwner: "ANYONE_CAN_CONTACT",
        whoCanViewGroup: "ALL_IN_DOMAIN_CAN_VIEW",
        whoCanPostMessage: "ALL_IN_DOMAIN_CAN_POST",
        whoCanViewMembership: "ALL_IN_DOMAIN_CAN_VIEW",
        whoCanModerateMembers: "OWNERS_AND_MANAGERS",
        whoCanJoin: "ALL_IN_DOMAIN_CAN_JOIN",
        allowExternalMembers: false,
      },
      groupMembers: [],
      loadingScopes: false,
      loadingDrives: false,
      groupDrives: [],
      drivesDisabled: false,
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },

  methods: {
    ...mapActions(["checkScopes", "getCompanySharedDrives"]),

    closeDialog() {
      this.$emit("close");
      this.currentStep = 1;
      this.group = {};
      this.groupMembers = [];
      this.groupDrives = [];
      this.groupTypeSelected = "public";
      this.groupConfig = {
        whoCanContactOwner: "ANYONE_CAN_CONTACT",
        whoCanViewGroup: "ALL_IN_DOMAIN_CAN_VIEW",
        whoCanPostMessage: "ALL_IN_DOMAIN_CAN_POST",
        whoCanViewMembership: "ALL_IN_DOMAIN_CAN_VIEW",
        whoCanModerateMembers: "OWNERS_AND_MANAGERS",
        whoCanJoin: "ALL_IN_DOMAIN_CAN_JOIN",
        allowExternalMembers: false,
      };
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    updateGroup(group) {
      this.group = group;
    },

    addMember(user) {
      this.groupMembers.push(user);
    },

    updateMember(user) {
      const memberIndex = this.groupMembers.findIndex(
        (member) => member.email === user.email
      );

      if (memberIndex !== -1) {
        this.groupMembers.splice(memberIndex, 1, user);
      } else {
        console.error(`updateMember(): Group member not found`);
      }
    },

    deleteMember(user) {
      const memberIndex = this.groupMembers.findIndex(
        (member) => member.email === user.email
      );

      if (memberIndex !== -1) {
        this.groupMembers.splice(memberIndex, 1);
      } else {
        console.error(`deleteMember(): Group member not found`);
      }
    },

    addGroupDrive(item) {
      this.groupDrives.push(item);
    },

    updateGroupDrives(item) {
      const driveIndex = this.groupDrives.findIndex(
        (drive) => drive.id === item.id
      );

      if (driveIndex !== -1) {
        this.groupDrives.splice(driveIndex, 1, item);
      } else {
        console.error(`updateGroupDrives(): Group drive not found`);
      }
    },

    deleteGroupDrives(item) {
      const driveIndex = this.groupDrives.findIndex(
        (drive) => drive.id === item.id
      );

      if (driveIndex !== -1) {
        this.groupDrives.splice(driveIndex, 1);
      } else {
        console.error(`deleteGroupDrives(): Group drive not found`);
      }
    },

    cantChangeStep(step) {
      return (
        step.disabled ||
        (step.id != 2 && step.id != 1 && Object.keys(this.group).length === 0)
      );
    },
  },

  computed: {
    ...mapGetters(["companySharedDrives", "scopeCode"]),

    stepNames() {
      return [
        {
          title: "Introdução",
          subtitle: "Beneficios de se utilizar grupos do Google Workspace",
          disabled: false,
          id: 1,
        },
        {
          title: "Criar grupo",
          subtitle: "Defina nome, email e descrição do grupo.",
          disabled: false,
          id: 2,
        },
        {
          title: "Configurar acesso",
          subtitle: "Defina permissões de acesso dos participantes.",
          disabled: false,
          id: 3,
        },
        {
          title: "Adicionar participantes",
          subtitle: "Adicione os usuários que desejar ao grupo.",
          disabled: false,
          id: 4,
        },
        {
          title: "Configurar drives compartilhados",
          subtitle: "Adicione e configure os drives compartilhados do grupo.",
          disabled: false,
          id: 5,
        },
      ];
    },

    mobile() {
      return this.windowSize.x <= 959;
    },
  },

  async mounted() {
    this.onResize();
    const driveScope = INCREMENTAL_SCOPES.ADMIN_DRIVE;
    if (!this.scopeCode(driveScope)) {
      this.loadingScopes = true;
      const payload = {
        scopes: [driveScope],
      };

      await this.checkScopes(payload)
        .then(() => {
          this.drivesDisabled = false;
        })
        .catch(() => {
          this.drivesDisabled = true;
        })
        .finally(() => {
          this.loadingScopes = false;
        });
    }
    if (!this.companySharedDrives.length) {
      this.loadingDrives = true;
      await this.getCompanySharedDrives();
      this.loadingDrives = false;
    }
  },
};
</script>

<style>
v-toolbar__extension {
  padding: 0px !important;
}

.v-stepper__step__step,
.v-stepper__step__step.primary {
  background-color: var(--v-accent-base) !important;
  border-color: var(--v-accent-base) !important;
}

.disabled > .v-stepper__step__step {
  background-color: rgb(199, 199, 199) !important;
  border-color: grey !important;
}
</style>
