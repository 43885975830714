<template>
  <v-container class="pa-0 my-0" style="max-width: 1160px">
    <v-card-title class="pa-0 mt-0">
      {{ $t("common.usersSelecteds") }} ({{ selectedUsersNumber }})
    </v-card-title>

    <Alert
      v-if="!hasSelected"
      :text="false"
      class="mt-4"
      color="error"
      dense
      dark
    >
      {{ $t("common.noUserPleaseSelect") }}
    </Alert>

    <v-autocomplete
      v-model="localSelectedUsers"
      :items="sortedUsers"
      open-on-clear
      clearable
      :loading="loadingSign"
      item-text="name"
      item-value="email"
      :label="hasSelected ? $t('common.usersSelecteds') : $t('common.users')"
      multiple
      chips
      outlined
      class="mt-5"
      color="accent"
      dense
      append-icon=""
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-bind="attrs"
          color="lighten-3"
          :input-value="selected"
          pill
          class="ma-1 link"
          @click.stop="firstUserSelected = item"
        >
          <v-avatar width="30" left>
            <img :src="item.photo || '@/assets/user-default.png'" />
          </v-avatar>
          <span class="px-2">
            {{ item.name }}
          </span>
          <v-icon
            color="secondary lighten-1"
            @click="parent.selectItem(item)"
            v-text="'mdi-close-circle'"
          />
        </v-chip>
      </template>

      <template v-slot:item="{ item, attrs, on }">
        <template>
          <v-list-item
            v-on="on"
            v-bind="attrs"
            :input-value="itemIsSelected(item)"
            active-class="light-blue lighten-5"
          >
            <v-list-item-action>
              <v-checkbox
                v-model="attrs.inputValue"
                :color="attrs.inputValue ? 'accent' : 'secondary'"
              />
            </v-list-item-action>
            <v-list-item-avatar size="32">
              <v-img :src="item.photo || '@/assets/user-default.png'" contain />
            </v-list-item-avatar>
            <v-list-item-content class="pl-4">
              <v-list-item-title
                :class="attrs.inputValue ? 'accent--text' : 'secondary--text'"
                v-html="item.name"
              />
              <v-list-item-subtitle v-html="item.email" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-autocomplete>

    <TemplatePreview
      v-if="templateHtml"
      :title="templateName || $t('common.vacationMessages')"
      :subject="templateSubject"
      :html="templateHtml"
      :infoToRender="firstUserSelected"
      show-expand
      hide-bottom
      is-vacation-template
      outlined
    />

    <v-card-actions class="px-0 mt-4">
      <v-spacer />
      <v-btn
        @click.stop="$emit('skip')"
        :disabled="!hasSelected || loadingSign"
        color="accent"
        class="px-5"
        large
        outlined
      >
        Pular
      </v-btn>
      <v-btn
        @click.stop="$emit('apply', localSelectedUsers)"
        :disabled="!hasSelected || loadingSign"
        :loading="loadingSign"
        color="accent"
        class="px-5"
        large
      >
        {{ $t("action.applyModel") }}
      </v-btn>
    </v-card-actions>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { sortByName } from "@/helpers/services/utils";
import TemplatePreview from "@/components/sign/general/TemplatePreview";

export default {
  name: "ConfirmTemplateApply",

  props: {
    selectedUsers: { type: Array, required: true },
    templateSubject: { type: String, required: true },
    templateName: { type: String, default: "" },
    templateHtml: { type: String, required: true },
  },

  components: { TemplatePreview },

  data() {
    return {
      search: "",
      firstUserSelected: null,
    };
  },

  computed: {
    ...mapGetters(["users", "loadingSign"]),

    selectedUsersNumber() {
      return this.localSelectedUsers.length;
    },

    localSelectedUsers: {
      get() {
        return this.selectedUsers;
      },
      set(new_val) {
        this.$emit("change:selected-users", new_val);
      },
    },

    hasSelected() {
      return this.selectedUsersNumber > 0;
    },

    sortedUsers() {
      return sortByName(this.users);
    },

    filteredUsers() {
      return this.search
        ? this.sortedUsers.filter(
            (user) =>
              // Filtra por nome
              user.name.toUpperCase().match(this.search.toUpperCase()) ||
              // Filtra por departamento
              user.departament.toUpperCase().match(this.search.toUpperCase()) ||
              // Filtra por email
              user.email.toUpperCase().match(this.search.toUpperCase())
          )
        : this.sortedUsers;
    },
  },

  methods: {
    itemIsSelected(item) {
      return this.localSelectedUsers.includes(item.email);
    },

    clickedOnUserCard(active, user, toggle) {
      if (active) {
        this.firstUserSelected = user;
      } else {
        toggle();
      }
    },
  },
};
</script>
<style>
.selected-border {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 3px !important;
  border-color: var(--v-primary-base) !important;
}
</style>
